<template>
  <div>
    <full-page-content-container>
      <grid-table
        v-if="rowData.length > 0"
        :column-defs="columnDefs"
        :row-data="rowData"
      >
      </grid-table>
    </full-page-content-container>
  </div>
</template>

<script>
import FullPageContentContainer from "../../../layouts/components/fullPage/FullPageContentContainer";
import GridTable from "../../../components/tables/GridTable";
import axios from "../../../../axios";
import gridTableParentMixin from "../../../../mixins/gridTableParentMixin";
export default {
  mixins: [gridTableParentMixin],
  name: "PaidSubscriptions",
  components: { GridTable, FullPageContentContainer },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "user",
          filter: true,
          sortable: true,
          hasOptionalDataFields: true,
          optionalDataFields: ["first_name", "last_name"],
        },
        {
          headerName: "Package",
          field: "package",
          filter: true,
          sortable: true,
        },
        // {
        //   headerName: "Package ID",
        //   field: "package_id",
        //   filter: true,
        //   sortable: true,
        // },
        {
          headerName: "Payment status",
          field: "payment_status",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date",
          type: "date",
          field: "date",
          filter: true,
          sortable: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/subscriptions/Paid`,
    };
  },
  methods: {
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          const data = res.data.data;
          this.rowData = data;
          loading.close();
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
  },
  mounted() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
  },
};
</script>

<style scoped></style>
